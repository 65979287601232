.youtube-stuff{

}

.youtube-stuff h4{
  color: #f2f2f2;
}

/* Youtube Component */
.youtube{
  width: 100%;
}

.youtube-thumb{
  display: inline;
}
.youtube-frame{
  padding: 15px 0;
  width: 70%;
  margin: 0 auto;
}
/*
iframe{
  width: 100%;
  height: 100%;
}
*/
.youtube-thumbs-main{
  margin: 0;
  width: 100%;
  background-color: #333333;

}
.youtube-thumbs-main ul{
  padding: 0;
  list-style: none;
  display: inline-block;
}

.youtube-thumbs-main ul li{
  display: inline-block;
  padding: 1rem;
  margin: 0;
  max-width: 200px;
}
.youtube-thumbs-main ul li:hover{
  cursor: pointer;
  color: #666666;
  background-color: #f0f0f0;
}
.youtube-thumbs-main ul li img{
  width: 90%;
}
.youtube-thumbs-main ul li h5{
  font-size: 15px;
  width: 90%;
  padding: 0.6rem 0;
  margin: 0 auto;
}

.youtube-thumbs-main .arrow-vid{
  background-color: #333333;
  margin: 0;
  padding: 0;
  width:inherit;
}
.youtube-thumbs-main .arrow-vid .ion{
  margin: 10px;
  border-radius: 3px;
  cursor: pointer;
  padding: 0;
  font-size: 30px;
  width: 70%;
}
.youtube-thumbs-main .arrow-vid .ion:hover{
  color: #333333;
  background-color: #f0f0f0;
}


.embed-container{
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow:
  hidden;
  width: 100%;
}
.embed-container iframe, .embed-container object, .embed-container embed{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


.youtube-thumbs-main .chev-vert{
  color: white;
  display: none;
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.2rem;
  vertical-align: middle;
}

.youtube-thumbs-main .chev-vert:hover{
  cursor: pointer;
  background-color: lightgrey;
}

.youtube-thumbs-main .chev-horz{
  color: white;
  padding: 10px 15px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;

}

.youtube-thumbs-main .chev-horz:hover i{
  cursor: pointer;
  color: #545454;
  background-color: lightgrey;
}

.side-piece{
  display: inline-block;
  height: 100px;
  margin: 40px 0 0 0;
  vertical-align: top;

}

@media (width < 1000px) {
  .side-piece{
    display: none;
  }

  .youtube-thumbs-main .chev-vert{
    display: inline-block;
  }

  .youtube-frame{
    width: 100%;
  }

  .youtube-thumbs-main ul li{
    display: block;
    padding: 1rem;
    margin: 0 auto;
    max-width: 80%;
  }
}
