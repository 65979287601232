.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* main css */
html *{
  margin: 0;
  padding: 0;
  color: #444444;
}
