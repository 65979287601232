.hireme-stuff{
  padding: 2rem 0;
}


.hireme-stuff iframe{
  width: 854;
  height: 1104;
}

.hireme-stuff h4{
  padding: 1rem;
}

.resume h5{
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  display: inline-block;
}

.resume a:hover *{
  color: orange;
}

.resume p{
  padding-bottom: 30px;
  max-width: 854px;
  margin: 0 auto;
  font-size: 18px;
}

.resume iframe{
  margin: 0 auto;
  display: block;
}

@media (width < 854px) {
  .resume a *{
    background-color: #444444;
    border-radius: 5px;
    color: white;
  }

  .resume iframe{
    display: none;
  }

  .resume p{
    font-size: 15px;
    max-width: 80%;

  }
}
