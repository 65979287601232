.projects{
  border-bottom: 2px solid lightgrey;
}
.project-stuff{
  padding: 20px 0;
}

.project-head{
  margin: 0 0 1rem 0;
}

.project-list{
  padding: 0 1rem;
  display: inline-block;
  width: 50%;
  margin: 0 auto;
}



.project-thumb{
  text-align: right;
}

.project-list img{
  height: 80px;
  margin: 12px;
}

.project-list a:hover *{
  color: orange;
}


@media (width < 1000px) {
  .project-list{
    padding: 1rem;
    display: block;
    width: 100%;
  }

  .project-list img{
    height: 130px;
    padding: 1rem;
  }
}

@media (width < 700px) {
  .project-list{
    padding: 1rem;
    display: block;
    width: 100%;
  }

  .project-list img{
    height: 100px;
    padding: 1rem;
  }
}
