.subhead-stuff{
  padding: 5px 0;
}

.subhead-stuff a{
  color: #444444;
}

.subhead-stuff a:hover *{
  color: orange;
}

.head-half{
  display: inline-block;
  width: 50%;
}

.subhead-stuff img{
  height: 45px;
  float: left;
}

.subhead-stuff h5{
  position: relative;
  display: inline-block;
  float: right;
  top: -5px;
  font-size: 32px;
}

@media (width < 600px) {
  .head-half{
    display: block;
    width: 100%;
    height: auto;
  }

  .subhead-stuff img{
    height: 45px;
    float: none;
  }

  .subhead-stuff h5{
    position:inherit;
    display: inline-block;
    float: none;
    text-align: center;
    font-size: 22px;
    padding: 0.25rem 0.5rem;
    margin: 10px;
    border-radius: 0.5rem;
    background-color: #444444;
    color: #e0e0e0;
  }

  .subhead-stuff *{
    color: #e0e0e0;
  }
}
